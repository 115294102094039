import { Injectable } from '@angular/core'
import { HttpClient, HttpEvent } from '@angular/common/http'
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { environment } from 'src/environments/environment'
import { CookieService } from 'ngx-cookie-service'
import { UserCookiePipe } from 'src/app/pipe/user-cookie-pipe'
import { map } from 'rxjs/operators'
import {jwtDecode} from 'jwt-decode'

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    ACCESS_TOKEN = 'access_token'
    REFRESH_TOKEN = 'refresh_token'
    private userDataSubject: BehaviorSubject<any> = new BehaviorSubject(null)
    userData$: Observable<any> = this.userDataSubject.asObservable()

    isLoggedIn: BehaviorSubject<boolean> = new  BehaviorSubject(false);
    // private isAuthenticated = false;

    constructor(private http: HttpClient,
        private cookie: CookieService,
        private userCookie: UserCookiePipe) {
        if (localStorage.getItem(this.ACCESS_TOKEN) && localStorage.getItem(this.REFRESH_TOKEN)) {
            const access_token = (<string>localStorage.getItem(this.ACCESS_TOKEN))
            const refresh_token = (<string>localStorage.getItem(this.REFRESH_TOKEN))
            this.userDataSubject.next({ access_token, refresh_token, userInfo: this.getUserDataFromToken(access_token) })
        }
    }

    get userData(): any {
        // return userData(userInfo, access_token, refresh_token) or null
        return this.userDataSubject.value
    }

    isAuthGuard(): Observable<boolean>{
        return this.isLoggedIn.asObservable();
      }

    login(user: any): Observable<any> {
        return this.http.post(`${environment.apiTokenUrl}/token`, user).pipe(
            map((res: any) => {
                const access_token = res?.data?.access_token
                const refresh_token = res?.data?.refresh_token
                this.userDataSubject.next({ access_token, refresh_token, userInfo: this.getUserDataFromToken(access_token) })
                localStorage.setItem(this.ACCESS_TOKEN, access_token)
                localStorage.setItem(this.REFRESH_TOKEN, refresh_token)
                // localStorage.setItem("DATE_DIFF", res?.data?.dateDiff);
                this.isLoggedIn.next(true);
                return res
            })
        )
    }

    logout(): void {
        localStorage.removeItem(this.ACCESS_TOKEN)
        localStorage.removeItem(this.REFRESH_TOKEN)
        localStorage.removeItem("MAIN_MENU");
        localStorage.removeItem("SUB_MENU");
        localStorage.removeItem("SUB_MENU");
        this.cookie.delete('user')
        this.userDataSubject.next(null)
        this.isLoggedIn.next(false);
        // Call http logout method for block refresh token
    }

    generateNewTokens(): Observable<HttpEvent<any>> {

        const refresh_token = this.userDataSubject.value?.refresh_token
        let user = JSON.parse(this.cookie.get('user'));
        let params = {
            "username": user.username,
            "refreshToken": refresh_token
        }

        return this.http.post(`${environment.apiTokenUrl}/refresh-token`, params).pipe(
            map((res: any) => {
                const access_token = res?.data?.access_token
                const refresh_token = res?.data?.refresh_token
                this.userDataSubject.next({ access_token, refresh_token, userData: this.getUserDataFromToken(access_token) })
                localStorage.setItem(this.ACCESS_TOKEN, access_token)
                localStorage.setItem(this.REFRESH_TOKEN, refresh_token)
                return res
            })
        )
    }
    isAuthenticated(): boolean {
        const refresh_token = this.userDataSubject.value?.refresh_token
        if (!refresh_token) {
            return false
        }
        return this.isAuthTokenValid(refresh_token)
    }

    isAuthTokenValid(token: string): boolean {
        const decoded: any = jwtDecode(token)
        // default decoded exp format is second
        const expMilSecond: number = decoded?.exp * 1000 // milliseconds
        const currentTime = Date.now() // milliseconds
        if (expMilSecond < currentTime) {
            return false
        }
        return true
    }

    getUserDataFromToken(token: string): any {
        const decoded: any = jwtDecode(token)
        return decoded.data
    }


}
