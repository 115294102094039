// import { CanActivateFn } from '@angular/router';

// export const authGuard: CanActivateFn = (route, state) => {
//   return true;
// };
import { Injectable } from '@angular/core';
// import { CanActivate, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service'
import { AuthService } from 'src/app/service/auth.service'
import { map, Observable, of } from 'rxjs';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router,
    private cookie: CookieService,
    public authService: AuthService,

  ) {}

  canActivate(): boolean {
    if (this.cookie.get('user')) {
      return true;
    } else {
      this.router.navigate(['register/event-list']);
      return false;
    }
  }

//   canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
//     return this.authService.isAuthGuard().pipe(map(u => {
//       if (!u) {
//         this.router.navigateByUrl("/auth/login");
//         return false;
//       } else {
//         return true
//       }
//     }));
//   }
}
