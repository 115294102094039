export const environment = {
    production: false,
    apiTokenUrl: "https://iudg3j8gjd.execute-api.ap-southeast-1.amazonaws.com/dev-auth-event",
    apiBaseUrl: "https://xa90j7vudc.execute-api.ap-southeast-1.amazonaws.com/dev-event",
    apiWebUrl: "https://pkwvrlsshc.execute-api.ap-southeast-1.amazonaws.com/dev-event-web"
    // production: true,
    // apiTokenUrl: "https://oe7qtpaclb.execute-api.ap-southeast-1.amazonaws.com/prod-auth-event",
    // apiBaseUrl: "https://rzyfxu0603.execute-api.ap-southeast-1.amazonaws.com/prod-event",
    // apiWebUrl: "https://buz72rgu99.execute-api.ap-southeast-1.amazonaws.com/prod-event-web"
};
