import { Pipe, PipeTransform } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Pipe({
    name: 'userCookie'
})

export class UserCookiePipe implements PipeTransform {
    user: any;
    constructor(private cookie: CookieService) {

        try {
            this.user = JSON.parse(this.cookie.get('user'));
        }

        catch (ex) {

        }

    }

    transform(value: string): any {
        if (value === '') {
            return this.user;
        } else {
            return this.user[value];
        }
    }
}
