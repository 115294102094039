import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { delay, map,  tap } from "rxjs/operators";
import { SharedService } from 'src/app/service/shared.service';

@Component({
  selector: 'app-misc-master',
  templateUrl: './misc-master.component.html',
  styleUrls: ['./misc-master.component.scss']
})
export class MiscMasterComponent implements OnInit {

  progressSpinnerDlg: boolean = false;
  private severity : any = {'s':'success', 'i':'info', 'w':'warn', 'e':'error'};
  constructor(private messageService: MessageService, private ss: SharedService) { }

  header : string = "แจ้งเตือน"
  acceptLabel : string ="ตกลง"
  rejectLabel : string ="ยกเลิก"

  ngOnInit() {
    this.ss.getEmittedValue().subscribe(item => this.progressSpinnerDlg = item);
  }

  public progressSpinner(show: boolean) {
    this.ss.progressSpinner(show)
  }

  // severity : ['s', 'i', 'w', 'e']
  public newMessage(sv : string, summaryTxt : string, detailTxt : string) {
    this.messageService.add({ severity : this.severity[sv], summary: summaryTxt, detail: detailTxt })
  }

  public newMessageAndDelay(sv : string, summaryTxt : string, detailTxt : string,delay : number) {
    this.messageService.add({ severity : this.severity[sv], summary: summaryTxt, detail: detailTxt })
    this.clearMessage(delay)
  }

  // key : [top-left : tl, top-center : tc, bottom-center : bc]
  public newMsgPosition(k : string, sv : string, summaryTxt : string, detailTxt : string) {
    this.messageService.add({key: k,  severity: this.severity[sv], summary: summaryTxt, detail: detailTxt })
  }

  public clearMessage(ms : number) {
    (async () => {
      await delay(ms);
      this.messageService.clear();
    })();
  }
}
