import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpResponse,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import { Observable, catchError, from, throwError } from 'rxjs';
import { switchMap, take, map } from 'rxjs/operators';
import { AuthService } from 'src/app/service/auth.service';
import { SpinnerService } from 'src/app/service/spinner.service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private authService: AuthService,
        private spinnerService: SpinnerService) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        var channelArray: Array<string> = ['/refresh-token', '/token',
            '/web-event-list', '/web-event-type-list', '/web-register-get',
            'web-event-get', 'web-register-save', 'web-register-list','web-team-save','web-team-list','web-team-get',
            'web-register-model-save', 'web-register-model-delete', 'web-images-save', 'web-images-delete',
            'web-register-transfer-save', 'web-register-transfer-delete', 'web-register-confirm',
            'web-register-model-list','web-policy-get','web-checkpoint-save','web-player-list','web-player-get','web-player-register-get',
        'web-checkpoint-list','web-register-result-list','web-register-model-result-list','web-register-get-by-keyword',
        'web-request-save','web-request-get','web-request-list','web-checkpoint-import'];
        var checkUrl: boolean = false
        if (request.url.indexOf('web-register-result-list') !== -1 || request.url.indexOf('checkpoint-list') !== -1) {
            this.spinnerService.noShow()
        }else{
            this.spinnerService.show()
        }

        // this.spinnerService.show()
        // console.log(request.url);

        for (let index = 0; index < channelArray.length; index++) {
            const element = channelArray[index];
            if (request.url.indexOf(element) !== -1) {
                checkUrl = true
            }

        }

        if (checkUrl) {
            return next.handle(request).pipe(map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    event = event.clone({ body: this.modifyBody(event.body) });
                }
                return event;
            }),
            catchError((error) => {
                if (error.status === 400) {
                  console.error('Bad Request:', error.message);
                //   setTimeout(() => location.reload(), 200)
                }
                return throwError(() => error);
              }));
        }

        // if (request.url.indexOf('/refresh-token') !== -1 || request.url.indexOf('/token') !== -1 ||
        //     'register/event-list') {
        //     return next.handle(request).pipe(map((event: HttpEvent<any>) => {
        //         if (event instanceof HttpResponse) {
        //             event = event.clone({body: this.modifyBody(event.body)});
        //         }
        //         return event;
        //     }));
        // }


        const data = this.authService.userData;
        const accessToken = data?.access_token;
        // console.log(accessToken);

        (accessToken)
        if (accessToken) {
            if (this.authService.isAuthTokenValid(accessToken)) {
                let modifiedReq = request.clone({
                    headers: request.headers.append('Authorization', `Bearer ${accessToken}`)
                });
                return next.handle(modifiedReq).pipe(map((event: HttpEvent<any>) => {
                    if (event instanceof HttpResponse) {
                        event = event.clone({ body: this.modifyBody(event.body) });
                    }
                    return event;
                }));
            }
            // stage 4: Going to generate new tokens
            // console.log(request.url);
            return this.authService.generateNewTokens()
                .pipe(
                    take(1),
                    switchMap((res: any) => {
                        let modifiedReq = request.clone({
                            headers: request.headers.append('Authorization', `Bearer ${res?.data?.access_token}`)
                        });
                        return next.handle(modifiedReq).pipe(map((event: HttpEvent<any>) => {
                            if (event instanceof HttpResponse) {
                                event = event.clone({ body: this.modifyBody(event.body) });
                            }
                            return event;
                        }),
                            catchError((error) => {
                                //Refresh Token Issue.
                                // console.log(error.status);

                                if (error.status == 403) {
                                    this.authService.logout()
                                }
                                setTimeout(() => location.reload(), 200)
                                return throwError(() => error);
                            })

                        );
                    }),
                    catchError((error) => {
                        //Refresh Token Issue.
                        this.authService.logout()
                        setTimeout(() => location.reload(), 200)
                        // console.log(error.status);
                        //   if (error.status == 403) {
                        //     this.authService.logout()
                        //   }
                        return throwError(() => error);
                    })
                )

        }
        return next.handle(request).pipe(map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                event = event.clone({ body: this.modifyBody(event.body) });
            }
            return event;
        }));
    }

    private modifyBody(body: any) {

        // console.log(body);

        this.spinnerService.hide()


        // console.log("hide");
    }
}
