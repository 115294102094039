export const environment = {
    production: false,
    apiTokenUrl: "https://iudg3j8gjd.execute-api.ap-southeast-1.amazonaws.com/dev-auth-event",
    apiBaseUrl: "https://xa90j7vudc.execute-api.ap-southeast-1.amazonaws.com/dev-event",
    apiWebUrl: "https://pkwvrlsshc.execute-api.ap-southeast-1.amazonaws.com/dev-event-web",
    // production: true,
    // apiTokenUrl: "https://oe7qtpaclb.execute-api.ap-southeast-1.amazonaws.com/prod-auth-event",
    // apiBaseUrl: "https://rzyfxu0603.execute-api.ap-southeast-1.amazonaws.com/prod-event",
    // apiWebUrl: "https://buz72rgu99.execute-api.ap-southeast-1.amazonaws.com/prod-event-web",
    firebase: {
        apiKey: "AIzaSyBxpVsOG9i-X8TG7g_uWoi1TmGU5xzUkmU",
        authDomain: "koompa-bf6fe.firebaseapp.com",
        databaseURL: "https://koompa-bf6fe-default-rtdb.asia-southeast1.firebasedatabase.app",
        projectId: "koompa-bf6fe",
        storageBucket: "koompa-bf6fe.firebasestorage.app",
        messagingSenderId: "106842405559",
        appId: "1:106842405559:web:93b7d7451b99afd7676b32",
        measurementId: "G-NNZ8G3WRJW"
    }

};
