import { Injectable } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  showIndex = 0;
  hideIndex = 0;

  constructor(private spinner: NgxSpinnerService) {}

  show() {
    this.showIndex++;
    this.spinner.show();
    // console.log('show spinner', this.showIndex);
  }

  noShow() {
    this.showIndex++;
    // this.spinner.show();
    // console.log('show spinner', this.showIndex);
  }

  hide() {
    this.spinner.hide();
    // this.hideIndex++;
    // if (this.showIndex === this.hideIndex) {
    //   this.spinner.hide();
    // }
  }
}
