import { NgModule } from '@angular/core';
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy,DatePipe } from '@angular/common';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AppLayoutModule } from './layout/app.layout.module';
import { NotfoundComponent } from './demo/components/notfound/notfound.component';
import { ProductService } from './demo/service/product.service';
import { CountryService } from './demo/service/country.service';
import { CustomerService } from './demo/service/customer.service';
import { EventService } from './demo/service/event.service';
import { IconService } from './demo/service/icon.service';
import { NodeService } from './demo/service/node.service';
import { PhotoService } from './demo/service/photo.service';
import { HttpClientModule, HTTP_INTERCEPTORS, } from '@angular/common/http'
import { AuthInterceptor } from './auth/auth.interceptor'
import { ErrorInterceptor } from './auth/error.interceptor'
import { MiscMasterComponent } from 'src/app/page/common/misc-master/misc-master.component'
import { NgxSpinnerModule } from "ngx-spinner";
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule } from '@angular/forms';
import { RippleModule } from 'primeng/ripple';
import { UserCookiePipe } from 'src/app/pipe/user-cookie-pipe'
import { MessageService } from 'primeng/api'
import { ConfirmationService } from 'primeng/api'
import { CommonModule } from '@angular/common'
import { PasswordModule } from 'primeng/password'
import { ConfirmDialogModule } from 'primeng/confirmdialog'
import { ToastModule } from 'primeng/toast'
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { AuthService } from 'src/app/service/auth.service'


@NgModule({
    declarations: [AppComponent, NotfoundComponent,MiscMasterComponent,UserCookiePipe],
    imports: [AppRoutingModule, AppLayoutModule,ToastModule,ConfirmDialogModule,NgxSpinnerModule,
        ProgressSpinnerModule,CommonModule
    ],
    providers: [
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        CountryService, CustomerService, EventService, IconService, NodeService,
        PhotoService, ProductService, UserCookiePipe, MessageService,
        ConfirmationService, DatePipe,MiscMasterComponent,AuthService
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
